import React from 'react';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import styles from './productDialog.module.css';
import { modifierDTO, tagDTO } from '../../../types/ProductDto';
import ModifierForm from '../ModifierForm/ModifierForm';
import ModifierItem from './ModifierItem/ModifierItem';
import { MdAdd, MdCancel, MdClose } from 'react-icons/md';
import { useProductDialogLogic } from './useProductDialogLogic';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';

interface ProductDialogProps {
  action: any;
  setAction: any;
  productData: any;
  setProductData: any;
  loading: boolean;
  handleSubmit: () => void;
  products: any;
}

const ProductDialog: React.FC<ProductDialogProps> = ({
  action,
  setAction,
  productData,
  setProductData,
  loading,
  handleSubmit,
  products
}) => {
  const {
    tags,
    ProductSchema,
    editModifier,
    addTag,
    productTypes,
    handleModifierSubmit,
    handleTagSubmit,
    buttonRef,
    setEditModifier,
    setAddTag,
    setSelectedTag,
    createProductTagQuery,
    handleTagDelete,
    setSure,
    sure,
    deleteProductTagQuery,
    selectedTag
  } = useProductDialogLogic(productData, setProductData);
  /*eslint-disable*/
  return (
    <Dialog className={styles.dialog} open={action === 'create' || action === 'update'}>
      <DialogTitle style={{ textAlign: 'center', position: 'relative' }}>
        {action === 'update' ? 'Termék módosítása' : 'Új termék létrehozása'}
        <IconButton
          style={{ position: 'absolute', top: '0', right: '0' }}
          onClick={() => {
            setAction(null);
            setProductData(null);
            setEditModifier(null);
            setAddTag(false);
          }}>
          <MdClose color='red' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={{
            name: productData?.name,
            description: productData?.description,
            isVisible: productData?.isVisible,
            productType: productData?.productType,
            orderNumber: productData?.orderNumber,
            isSingletonCartItem: productData?.isSingletonCartItem ?? false,
            suggestedQuantity: productData?.suggestedQuantity ?? 1,
            connectedProducts: productData?.connectedProducts ?? []
          }}
          validationSchema={ProductSchema}
          onSubmit={() => {
            handleSubmit();
            console.log(productData);
          }}>
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.mainForm}>
              <TextField
                id='name'
                name='name'
                autoComplete='name'
                value={values.name}
                autoFocus
                label={'Termék neve'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setProductData({ ...productData, name: e.target.value });
                }}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && Boolean(errors.name)}
              />
              <TextField
                id='description'
                name='description'
                autoComplete='description'
                value={values.description}
                autoFocus
                label={'Leírás'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setProductData({ ...productData, description: e.target.value });
                }}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && Boolean(errors.description)}
              />
              <FormControl className={styles.input}>
                <InputLabel style={{ background: 'var(--color-background-cards)' }}>Termék típusa</InputLabel>
                <Select
                  label='Termék típusa'
                  id='productType'
                  name='productType'
                  autoComplete='productType'
                  value={productData?.productType}
                  onChange={(e) => {
                    handleChange(e);
                    setProductData({ ...productData, productType: e.target.value });
                  }}
                  error={touched.productType && Boolean(errors.productType)}>
                  {productTypes?.map((type: string) => (
                    <MenuItem key={`select_item_${type}`} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={styles.input}>
                <InputLabel style={{ background: 'var(--color-background-cards)' }}>Kapcsolt termékek</InputLabel>
                <Select
                  multiple
                  label='Kapcsolt termékek'
                  id='connectedProducts'
                  name='connectedProducts'
                  autoComplete='connectedProducts'
                  value={productData?.connectedProducts ?? []}
                  onChange={(e) => {
                    handleChange(e);

                    console.log(productData?.connectedProducts);
                    console.log(values.connectedProducts);
                    console.log(e.target.value);
                    setProductData({ ...productData, connectedProducts: e.target.value });
                  }}
                  error={touched.connectedProducts && Boolean(errors.connectedProducts)}>
                  {productData?.id &&
                    products
                      ?.filter((product: any) => product?.id !== productData?.id)
                      ?.map((product: any) => (
                        <MenuItem key={`select_item_${product.id}`} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <TextField
                inputProps={{ type: 'number', min: 1 }}
                id='orderNumber'
                name='orderNumber'
                value={productData?.orderNumber}
                autoFocus
                label={'Sorszám (opcionális)'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setProductData({ ...productData, orderNumber: +e.target.value });
                }}
                error={touched.orderNumber && Boolean(errors.orderNumber)}
                helperText={touched.orderNumber && Boolean(errors.orderNumber)}
              />
              <TextField
                inputProps={{ type: 'number', min: 1 }}
                id='suggestedQuantity'
                name='suggestedQuantity'
                value={productData?.suggestedQuantity}
                autoFocus
                label={'Javasolt mennyiség'}
                className={styles.input}
                onChange={(e) => {
                  handleChange(e);
                  setProductData({ ...productData, suggestedQuantity: +e.target.value });
                }}
                error={touched.suggestedQuantity && Boolean(errors.suggestedQuantity)}
                helperText={touched.suggestedQuantity && Boolean(errors.suggestedQuantity)}
              />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productData?.isVisible}
                      onChange={() =>
                        setProductData({ ...productData, isVisible: productData?.isVisible ? false : true })
                      }
                    />
                  }
                  label={'Aktív?'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productData?.isSingletonCartItem}
                      onChange={() =>
                        setProductData({
                          ...productData,
                          isSingletonCartItem: productData?.isSingletonCartItem ? false : true
                        })
                      }
                    />
                  }
                  label={'Singleton termék'}
                />
              </div>

              <button ref={buttonRef} disabled={loading} type='submit' style={{ display: 'none' }}>
                SUBMIT
              </button>
            </form>
          )}
        </Formik>
        <div className={styles.groups}>
          <h3>Címkék</h3>
          <IconButton disabled={action === 'create'} onClick={() => setAddTag(!addTag)}>
            {addTag ? <MdCancel /> : <MdAdd />}
          </IconButton>
        </div>
        {addTag && (
          <div className={styles.tag_section}>
            <Select
              id='tags'
              className={styles.tag_selector}
              name='tags'
              onChange={(e: any) => setSelectedTag(tags?.find((t: tagDTO) => t.name === e.target.value))}>
              {tags?.map((t: tagDTO, idx: number) => (
                <MenuItem key={`tag_select_item_${idx}`} value={t.name}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
            <Button
              disabled={createProductTagQuery.isLoading}
              className={styles.tag_add_button}
              variant='outlined'
              onClick={() => handleTagSubmit()}>
              {createProductTagQuery.isLoading ? <CircularProgress size={24} /> : 'Hozzáad'}
            </Button>
          </div>
        )}
        <>
          {productData?.tags?.length > 0 &&
            productData?.tags?.map((t: tagDTO) => (
              <Chip
                key={t.name}
                className={styles.tag_chip}
                label={t.name}
                onDelete={() => {
                  setSelectedTag(t);
                  setSure(true);
                }}
              />
            ))}
        </>
        <div className={styles.groups}>
          <h3>Termék tulajdonságok</h3>
          <IconButton
            onClick={() => {
              if (editModifier) {
                setEditModifier(null);
              } else {
                setEditModifier('new');
              }
            }}>
            {editModifier ? <MdCancel /> : <MdAdd />}
          </IconButton>
        </div>

        {productData?.modifiers?.length > 0 &&
          !editModifier &&
          productData?.modifiers
            ?.sort((a: modifierDTO, b: modifierDTO) =>
              a.modifierKey.toLowerCase().localeCompare(b.modifierKey.toLowerCase())
            )
            ?.map((m: modifierDTO) => (
              <ModifierItem
                key={`modifierItem_${m?.id}`}
                m={m}
                setEditModifier={setEditModifier}
                productData={productData}
                setProductData={setProductData}
              />
            ))}
        {editModifier && <ModifierForm handleSubmit={handleModifierSubmit} modifier={editModifier} />}
        <Divider />
        <br />
        <Button
          fullWidth
          disabled={loading || editModifier}
          variant='contained'
          onClick={() => (buttonRef.current as any).click()}>
          {loading ? (
            <CircularProgress size={24} />
          ) : action === 'update' ? (
            'Termék módosítása'
          ) : (
            'Új termék létrehozása'
          )}
        </Button>
      </DialogContent>
      <ConfirmDialog
        open={sure}
        setOpen={setSure}
        title={`Biztos le akarod választani a ${selectedTag?.name} nevű címkét?`}
        action={handleTagDelete}
        loading={deleteProductTagQuery.isLoading}
      />
    </Dialog>
  );
};

export default ProductDialog;
