import React from 'react';
import { useUsersLogic } from './useUsersLogic';
import styles from './users.module.css';
import { Accordion, AccordionDetails, AccordionSummary, Button, Slide, TextField } from '@mui/material';
import UserSubCreator from '../../components/userComponents/UserSubCreator/UserSubCreator';
import { MdArrowDropDown } from 'react-icons/md';
import { routes } from '../../config/routes';

const Users = () => {
  const { users, subscriptions, navigate, search, setSearch } = useUsersLogic();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Felhasználók</h1>
      </div>
      <UserSubCreator />
      <TextField
        placeholder='Keresés felhasználónév alapján'
        style={{ width: '100%', maxWidth: '400px', height: '30px', marginTop: '2rem' }}
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      <div className={styles.userListWrapper}>
        <table className={styles.ownGridHeader}>
          <thead className={styles.thead}>
            <tr className={styles.tablecell}>
              <td>ExternalId</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Felhasználónév</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Teljes név</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Email cím</td>
            </tr>
            <tr className={styles.tablecell}>
              <td>Státusz</td>
            </tr>
          </thead>
        </table>

        {users
          ?.filter((user: any) => user?.username?.includes(search))
          ?.map((user: any) => (
            <div key={`user_item_${user?.externalId}`} className={styles.gridItem}>
              <Slide direction='right' timeout={500} in={true}>
                <Accordion className={styles.service}>
                  <AccordionSummary expandIcon={<MdArrowDropDown />} className={styles.serviceSummary}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        height: '100%'
                      }}>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem}>{user?.externalId}</p>
                      </div>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem}>{user?.username}</p>
                      </div>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem}>
                          {user?.lastname} {user?.firstname}
                        </p>
                      </div>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem}>{user?.email}</p>
                      </div>
                      <div className={styles.tablecell}>
                        <div className={styles.tableCellItem}>
                          {user?.active ? (
                            <div className={styles.dot} style={{ backgroundColor: 'green' }}></div>
                          ) : (
                            <div className={styles.dot} style={{ backgroundColor: 'red' }}></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderTop: '1px solid var(--color-main)' }}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        height: '100%'
                      }}>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem} style={{ color: 'black' }}>
                          Sub azonosító:{' '}
                          {subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.id
                            ? subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.id
                            : '-'}
                        </p>
                      </div>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem} style={{ color: 'black' }}>
                          Kártya regisztráció:{' '}
                          {subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.isCardRegistered
                            ? 'Van'
                            : 'Nincs'}
                        </p>
                      </div>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem} style={{ color: 'black' }}>
                          Valuta:{' '}
                          {subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.currency
                            ? subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.currency
                            : '-'}
                        </p>
                      </div>
                      <div className={styles.tablecell}>
                        <p className={styles.tableCellItem} style={{ color: 'black' }}>
                          Szolgáltatások száma:{' '}
                          {subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.productInstances?.length
                            ? subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.productInstances
                                ?.length
                            : '-'}
                        </p>
                      </div>
                      <div className={styles.tablecell}>
                        <div className={styles.tableCellItem} style={{ color: 'black' }}>
                          <Button
                            disabled={!subscriptions?.find((sub: any) => sub.userId === user?.externalId)}
                            variant='outlined'
                            onClick={() => {
                              navigate(
                                `${routes.SUBSCRIPTIONS.to}/${
                                  subscriptions?.find((sub: any) => sub.userId === user?.externalId)?.id
                                }`
                              );
                            }}>
                            Tovább
                          </Button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Slide>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Users;
