import { TablePagination } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { QueryOptionDto } from '../../../../../types/QueryBuilderDtos';
import styles from './paginationEditor.module.css';

interface PaginationEditorProps {
  options: any[];
  setOptions: any;
  itemsLength: any;
}

const PaginationEditor: React.FC<PaginationEditorProps> = ({ options, setOptions, itemsLength }) => {
  const getOptions = () => {
    let options = [5, 10, 25, 50, 100, 200, 500, 1000];
    options.push(parseInt(itemsLength));
    return [...options.sort((a, b) => a - b)];
  };

  /*eslint-disable*/

  const [currentPage, setCurrentPage] = useState(
    parseInt(
      (options as any)?.find((o: QueryOptionDto) => o.name === 'pagination').value === ''
        ? 1
        : options
            ?.find((o: QueryOptionDto) => o.name === 'pagination')
            ?.value.split('&')[1]
            ?.split('=')[1]
    )
  );

  const [currentPageSize, setCurrentPageSize] = useState(
    parseInt(
      options?.find((o: QueryOptionDto) => o.name === 'pagination').value === ''
        ? 10
        : options
            ?.find((o: QueryOptionDto) => o.name === 'pagination')
            ?.value.split('&')[0]
            ?.split('=')[1]
    )
  );

  const handleChangePage = (e: any, newPage: any) => {
    setCurrentPage(newPage + 1);
    setOptions(
      options?.map((o: QueryOptionDto) => {
        if (o.name === 'pagination') {
          return {
            ...o,
            value: `size=${currentPageSize}&page=${newPage + 1}`
          };
        } else return o;
      })
    );
  };

  const handleChangePageSize = (e: any) => {
    setCurrentPageSize(e.target.value + 1);
    setOptions(
      options?.map((o: QueryOptionDto) => {
        if (o.name === 'pagination') {
          return {
            ...o,
            value: `size=${e.target.value}&page=${1}`
          };
        } else return o;
      })
    );
  };

  useEffect(() => {
    setCurrentPageSize(
      parseInt(
        options?.find((o) => o.name === 'pagination').value === ''
          ? 10
          : options
              ?.find((o) => o.name === 'pagination')
              ?.value.split('&')[0]
              ?.split('=')[1]
      )
    );
    setCurrentPage(
      parseInt(
        options?.find((o) => o.name === 'pagination').value === ''
          ? 1
          : options
              ?.find((o) => o.name === 'pagination')
              ?.value.split('&')[1]
              ?.split('=')[1]
      )
    );
  }, [options]);

  return (
    <div>
      <TablePagination
        className={styles.showXs}
        disabled={itemsLength <= 5 || itemsLength === undefined}
        component='div'
        count={itemsLength ? itemsLength : 10}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={currentPageSize}
        labelRowsPerPage={'Feliratkozás egy oldalon: '}
        rowsPerPageOptions={getOptions()}
        onRowsPerPageChange={handleChangePageSize}
      />
      <TablePagination
        className={styles.hideXs}
        disabled={itemsLength <= 5 || itemsLength === undefined}
        component='div'
        count={itemsLength ? itemsLength : 10}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={currentPageSize}
        labelRowsPerPage={'Feliratkozás egy oldalon: '}
        rowsPerPageOptions={getOptions()}
        onRowsPerPageChange={handleChangePageSize}
      />
    </div>
  );
};

export default PaginationEditor;
