import { useQuery } from '@tanstack/react-query';
import userQueries from '../../api/queries/UserQueries';
import compartmentQueries from '../../api/queries/SubscriptionQueries';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/routes';

export const useUsersLogic = () => {
  const [users, setUsers] = useState<any>();

  const [search, setSearch] = useState<string>('');

  const [subscriptions, setSubscritions] = useState<any>([]);

  const navigate = useNavigate();

  /*eslint-disable*/

  const userFetchQuery = useQuery({
    queryKey: ['users'],
    queryFn: () => userQueries.fetchAllUsers(),
    onSuccess: (data: any) => {
      setUsers(data);
    }
  });

  const subscriptionsFetchQuery = useQuery({
    queryKey: ['subscriptionsAll'],
    queryFn: () => compartmentQueries.getAllSubscription({ search: '?size=100000&sort=id,asc' }),
    onSuccess: (data: any) => {
      setSubscritions(data?._embedded?.subscriptionDTOList);
    }
  });

  const routeChange = (id: any) => {
    navigate(`${routes.USERS.to}/${id}`)
  }

  return { users, routeChange, subscriptions, navigate, setSearch, search };
};
