import { Button, TextField } from '@mui/material';
import { Formik } from 'formik';
import styles from './billingForm.module.css';
import React from 'react';
import { useBillingFormLogic } from './useBillingFormLogic';

const BillingForm = () => {
  const { formData, setFormData, editBilling, setEditBilling, buttonRef, handleSubmit } = useBillingFormLogic();

  return (
    <>
      <Formik enableReinitialize initialValues={formData} onSubmit={handleSubmit}>
        {({ /*errors, touched*/ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.billing_form}>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Keresztnév</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.firstName}
                className={styles.billing_input}
                autoFocus
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Vezetéknév</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.lastName}
                className={styles.billing_input}
                autoFocus
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>E-mail cím</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.email}
                className={styles.billing_input}
                autoFocus
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Cégnév</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.companyName}
                className={styles.billing_input}
                autoFocus
                onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <h3 className={styles.dataTitle} style={{ color: 'black' }}>
                Számlázási cím
              </h3>
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Ország</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.billingAddress?.country}
                className={styles.billing_input}
                autoFocus
                onChange={(e) =>
                  setFormData({ ...formData, billingAddress: { ...formData?.billingAddress, country: e.target.value } })
                }
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Irányítószám</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.billingAddress?.zipCode}
                className={styles.billing_input}
                autoFocus
                onChange={(e) =>
                  setFormData({ ...formData, billingAddress: { ...formData?.billingAddress, zipCode: e.target.value } })
                }
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Város</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.billingAddress?.city}
                className={styles.billing_input}
                autoFocus
                onChange={(e) =>
                  setFormData({ ...formData, billingAddress: { ...formData?.billingAddress, city: e.target.value } })
                }
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <div className={styles.dataBilling}>
              <p className={styles.dataTitle}>Utca és házszám</p>
              <TextField
                variant='standard'
                disabled={!editBilling}
                value={formData?.billingAddress?.streetAndNumber}
                className={styles.billing_input}
                autoFocus
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    billingAddress: { ...formData?.billingAddress, streetAndNumber: e.target.value }
                  })
                }
                // error={Boolean(errors.title)}
                // helperText={<>{errors.title}</>}
              />
            </div>
            <button ref={buttonRef} disabled={false} type='submit' style={{ display: 'none' }}>
              SUBMIT
            </button>
          </form>
        )}
      </Formik>
      <div className={styles.billing_form_buttons}>
        <Button onClick={() => (editBilling ? (buttonRef?.current as any)?.click() : setEditBilling(true))}>
          {editBilling ? 'Mentés' : 'Módosítás'}
        </Button>
        {editBilling && <Button onClick={() => setEditBilling(false)}>{'Mégsem'}</Button>}
      </div>
    </>
  );
};

export default BillingForm;
