import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import { MdAddCircleOutline, MdCheck, MdClose } from 'react-icons/md';
import styles from './subAdditionalHandler.module.css';
import { AdditionalsProp } from '../InstanceForm';

export type SubAdditionalHandlerProps = {
  additionalProp: string;
  additionals: any;
  setAdditionals: React.Dispatch<React.SetStateAction<AdditionalsProp>>;
  edit: boolean;
};

const SubAdditionalHandler: React.FC<SubAdditionalHandlerProps> = ({
  additionalProp,
  additionals,
  setAdditionals,
  edit
}) => {
  const [addSubAdditional, setAddSubAdditional] = useState<string>('');
  const [newSubAdditional, setNewSubAdditional] = useState<string>('');
  const [newSubAddSelected, setNewSubAddSelected] = useState<boolean>(false);

  const inputTypes = ['text', 'password', 'url', 'username/password'];
  const [newSubAddType, setNewSubAddType] = useState<string>(inputTypes[0]);

  return (
    <>
      {addSubAdditional !== additionalProp && edit && (
        <div className={styles.newSubAdd} style={{ justifyContent: 'center' }}>
          <IconButton onClick={() => setAddSubAdditional(additionalProp)}>
            <MdAddCircleOutline />
          </IconButton>
        </div>
      )}
      {!newSubAddSelected && addSubAdditional == additionalProp && (
        <div className={styles.newSubAdd}>
          <FormControl style={{ width: '75%' }}>
            <InputLabel>Field type select</InputLabel>
            <Select
              name='newSubAdditionalType'
              autoComplete='newSubAdditionalType'
              value={newSubAddType}
              label={'Field type select'}
              autoFocus
              style={{ width: '100%' }}
              onChange={(e) => {
                e.preventDefault();
                setNewSubAddType(e.target.value);
              }}>
              {inputTypes?.map((type: string) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            onClick={() => {
              setNewSubAddSelected(true);
            }}>
            <MdCheck color='green' />
          </IconButton>
          <IconButton onClick={() => setAddSubAdditional('')}>
            <MdClose color='red' />
          </IconButton>
        </div>
      )}
      {addSubAdditional == additionalProp && newSubAddSelected && (
        <div className={styles.newSubAdd}>
          <TextField
            name='newSubAdditional'
            autoComplete='newSubAdditional'
            value={newSubAdditional}
            label={newSubAddType === 'username/password' ? 'Field name prefix' : 'Field name'}
            autoFocus
            style={{ width: '70%' }}
            onChange={(e) => {
              e.preventDefault();
              setNewSubAdditional(e.target.value);
            }}
          />
          <IconButton
            onClick={() => {
              if (newSubAddType === 'username/password') {
                setAdditionals({
                  ...additionals,
                  [additionalProp]: JSON.stringify({
                    ...JSON.parse(additionals[additionalProp]),
                    [`${newSubAdditional}_username`]: { type: 'text', value: '' },
                    [`${newSubAdditional}_password`]: { type: 'password', value: '' }
                  })
                });
              } else {
                setAdditionals({
                  ...additionals,
                  [additionalProp]: JSON.stringify({
                    ...JSON.parse(additionals[additionalProp]),
                    [newSubAdditional]: { type: newSubAddType, value: '' }
                  })
                });
              }

              setNewSubAddSelected(false);
              setNewSubAddType('text');
              setNewSubAdditional('');
              setAddSubAdditional('');
            }}>
            <MdCheck color='green' />
          </IconButton>
          <IconButton onClick={() => setNewSubAddSelected(false)}>
            <MdClose color='red' />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default SubAdditionalHandler;
