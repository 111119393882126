import React, { useState } from 'react';
import styles from './instanceForm.module.css';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import {
  MdAddCircleOutline,
  MdClose,
  MdDelete,
  MdRemoveCircleOutline,
  MdVisibility,
  MdVisibilityOff
} from 'react-icons/md';
import SubAdditionalHandler from './SubAdditionalHandler/SubAdditionalHandler';

export type AdditionalProp = {
  type: string;
  value: string;
};

export type AdditionalsProp = {
  [key: string]: { [key: string]: AdditionalProp };
};

interface InstanceFormProps {
  handleSubmit: () => void;
  loading: boolean;
  setAdditionals: React.Dispatch<React.SetStateAction<AdditionalsProp>>;
  additionals: any;
  oriAdditionals: any;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  edit: boolean;
}
/*eslint-disable*/
const InstanceForm: React.FC<InstanceFormProps> = ({
  handleSubmit,
  setAdditionals,
  additionals,
  loading,
  oriAdditionals,
  setEdit,
  edit
}) => {
  const [newAdditional, setNewAdditional] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const [visiblePassword, setVisiblePassword] = useState<string>('');
  const additionalPropTypes = ['user/pw', 'user/pw/url'];
  const [newAddType, setNewAddType] = useState<string>(additionalPropTypes[0]);
  const [hover, setHover] = useState<string>('');

  return (
    <div className={styles.form}>
      {Object.keys(additionals)
        ?.sort((a: string, b: string) => a.localeCompare(b))
        ?.map((aP: string, idx: number) => (
          <>
            <div className={styles.detailRow}>
              <p className={styles.rowItemTitle}>{aP}</p>
              <IconButton
                type='button'
                disabled={!edit}
                onClick={() => {
                  const tempAdditionals = additionals;
                  delete tempAdditionals[aP];
                  setAdditionals({ ...tempAdditionals });
                }}>
                <MdDelete color={!edit ? 'lightgrey' : 'red'} />
              </IconButton>
              {additionals[aP]?.includes('{') ? (
                Object.keys(JSON.parse(additionals[aP]))?.map((k: string, idy: number) => (
                  <div
                    className={styles.fieldWrapper}
                    style={{
                      width:
                        Object.keys(JSON.parse(additionals[aP]))[idy + 1]?.split('_')[0] !== undefined &&
                        Object.keys(JSON.parse(additionals[aP]))[idy + 1]?.split('_')[0] ===
                          Object.keys(JSON.parse(additionals[aP]))[idy + 2]?.split('_')[0] &&
                        Object.keys(JSON.parse(additionals[aP]))[idy - 1]?.split('_')[0] !== k?.split('_')[0] &&
                        idy % 2 == 0
                          ? '100%'
                          : '48.5%'
                    }}
                    onMouseEnter={() => setHover(aP + k)}
                    onMouseLeave={() => setHover('')}>
                    <TextField
                      disabled={!edit}
                      label={k}
                      name={k}
                      type={visiblePassword == aP + k ? 'text' : JSON.parse(additionals[aP])[k]?.type}
                      autoComplete={JSON.parse(additionals[aP])[k]?.value}
                      value={JSON.parse(additionals[aP])[k]?.value}
                      autoFocus={idy == 0}
                      className={styles.input1}
                      style={{ width: hover === aP + k && edit ? '80%' : '100%' }}
                      onChange={(e) => {
                        e.preventDefault();
                        setAdditionals({
                          ...additionals,
                          [aP]: JSON.stringify({
                            ...JSON.parse(additionals[aP]),
                            [k]: { ...JSON.parse(additionals[aP])[k], value: e.target.value }
                          })
                        });
                      }}
                      InputProps={
                        JSON.parse(additionals[aP])[k]?.type === 'password'
                          ? {
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() =>
                                      visiblePassword == aP + k ? setVisiblePassword('') : setVisiblePassword(aP + k)
                                    }>
                                    {visiblePassword == aP + k ? <MdVisibility /> : <MdVisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                          : {}
                      }
                    />
                    {/* {hover === aP + k && edit && ( */}
                    <IconButton
                      className={styles.subAddRemove}
                      style={{ opacity: hover === aP + k && edit ? 1 : 0 }}
                      onClick={() => {
                        const tempAdditional = JSON.parse(additionals[aP]);
                        delete tempAdditional[k];
                        setAdditionals({ ...additionals, [aP]: JSON.stringify(tempAdditional) });
                      }}>
                      <MdRemoveCircleOutline color='red' />
                    </IconButton>
                    {/* )} */}
                  </div>
                ))
              ) : (
                <div className={styles.fieldWrapper}>
                  <TextField
                    disabled={!edit}
                    name={aP}
                    autoComplete={aP}
                    value={additionals[aP]}
                    autoFocus
                    className={styles.input}
                    onChange={(e) => {
                      e.preventDefault();
                      setAdditionals({ ...additionals, [aP]: e.target.value });
                    }}
                  />
                  <IconButton
                    type='button'
                    disabled={!edit}
                    onClick={() => {
                      const tempAdditionals = additionals;
                      delete tempAdditionals[aP];
                      setAdditionals({ ...tempAdditionals });
                    }}>
                    <MdDelete color={!edit ? 'lightgrey' : 'red'} />
                  </IconButton>
                </div>
              )}
              <SubAdditionalHandler
                edit={edit}
                additionalProp={aP}
                additionals={additionals}
                setAdditionals={setAdditionals}
              />
            </div>
            {!edit ? (
              idx !== Object.keys(additionals)?.length - 1 ? (
                <Divider variant='middle' style={{ width: '90%' }}></Divider>
              ) : (
                <></>
              )
            ) : (
              <Divider variant='middle' style={{ width: '90%' }}></Divider>
            )}
          </>
        ))}

      <div className={styles.newAdditional} style={{ justifyContent: isAdd ? 'space-between' : 'center' }}>
        {isAdd && (
          <>
            <FormControl style={{ width: '30%' }}>
              <InputLabel>Template select</InputLabel>
              <Select
                name='newSubAdditionalType'
                autoComplete='newSubAdditionalType'
                value={newAddType}
                label={'Template select'}
                autoFocus
                style={{ width: '100%' }}
                onChange={(e) => {
                  e.preventDefault();
                  setNewAddType(e.target.value);
                }}>
                {additionalPropTypes?.map((type: string) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name='newAdditional'
              autoComplete='newAdditional'
              value={newAdditional}
              label={'Property name'}
              autoFocus
              className={styles.input}
              onChange={(e) => {
                e.preventDefault();
                setNewAdditional(e.target.value);
              }}
            />
            <div className={styles.btnWrapper}>
              <Button
                style={{ marginLeft: '1.2rem', width: '70%' }}
                variant='outlined'
                type='button'
                onClick={() => {
                  if (newAddType == 'user/pw') {
                    setAdditionals({
                      ...additionals,
                      [newAdditional]: JSON.stringify({
                        Username: { type: 'text', value: '' },
                        Password: { type: 'password', value: '' }
                      })
                    });
                  } else {
                    setAdditionals({
                      ...additionals,
                      [newAdditional]: JSON.stringify({
                        Username: { type: 'text', value: '' },
                        Password: { type: 'password', value: '' },
                        Url: { type: 'url', value: '' }
                      })
                    });
                  }

                  setNewAdditional('');
                  setIsAdd(false);
                }}>
                Hozzáad
              </Button>
              <IconButton
                type='button'
                onClick={() => {
                  setIsAdd(false);
                  setNewAdditional('');
                }}>
                <MdClose />
              </IconButton>
            </div>
          </>
        )}
        {!isAdd && edit && (
          <IconButton onClick={() => setIsAdd(true)}>
            <MdAddCircleOutline />
          </IconButton>
        )}
      </div>

      <div className={styles.buttonsContainer}>
        <button
          disabled={loading}
          onClick={() => handleSubmit()}
          className={styles.button}
          type='button'
          style={{ display: edit ? 'unset' : 'none' }}>
          {loading ? <CircularProgress size={24} /> : 'Mentés'}
        </button>
        <button
          className={styles.button}
          type='button'
          style={{ display: edit ? 'unset' : 'none' }}
          onClick={() => {
            setEdit(false);
            setIsAdd(false);
            setAdditionals(oriAdditionals);
          }}>
          Mégsem
        </button>

        {!edit && (
          <button
            className={styles.button}
            type='button'
            onClick={() => {
              setEdit(true);
            }}>
            Elérés módosítása
          </button>
        )}
      </div>
    </div>
  );
};

export default InstanceForm;
