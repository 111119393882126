import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import UserQueries from '../../../api/queries/UserQueries';
import useGeneralStore from '../../../store/GeneralStore';
import { useToasterLogic } from '../../../utils/useToasterLogic';

export const useBillingFormLogic = () => {
  const { subscription, currentCompartment }: any = useGeneralStore((state) => ({
    subscription: state.subscription,
    currentCompartment: state.currentCompartment
  }));

  const buttonRef = useRef(null);
  const [editBilling, setEditBilling] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>();
  const { successToast, errorToast } = useToasterLogic();

  /*eslint-disable*/
  const userBillingQuery = useQuery({
    queryKey: ['userBilling', subscription?.id],
    queryFn: () => UserQueries.fetchUserBilling({ compId: currentCompartment?.id, subId: subscription?.id }),
    onSuccess: (data: any) => {
      setFormData(data);
    },

    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!subscription
  });

  const updateBillingInfo = useMutation({
    mutationFn: () => UserQueries.putUserBilling({ compId: currentCompartment?.id, subId: subscription?.id, billingData: formData }),
    onSuccess: () => {
      successToast('Sikeres módosítás')
    },
    onError: () => {
      errorToast('Sikertelen módosítás')
    }
  })
  const createBillingInfo = useMutation({
    mutationFn: () => UserQueries.postUserBilling({ compId: currentCompartment?.id, subId: subscription?.id, billingData: formData }),
    onSuccess: () => {
      successToast('Sikeres módosítás')
    },
    onError: () => {
      errorToast('Sikertelen módosítás')
    }
  })

  const handleSubmit = () => {
    userBillingQuery?.data ? updateBillingInfo.mutate() : createBillingInfo.mutate()
  }

  return {
    formData,
    setFormData,
    buttonRef,
    setEditBilling,
    editBilling,
    handleSubmit
  }
}
