import React from 'react';
import styles from './footer.module.css';
import email from '../../../assets/icons/email.svg';
import location from '../../../assets/icons/location.svg';
import phone from '../../../assets/icons/phone.svg';
import logo from '../../../assets/images/datatronic_logo_white.png';
import FooterContact from './footer/footerContact/FooterContact';
// import FooterIcons from './footer/footerIcons/FooterIcons';
import AuthService from '../../../services/AuthService';
import { useLocation } from 'react-router-dom';
// import { routes } from '../../../config/routes';

const Footer = () => {
  const locationURL = useLocation();
  const page = locationURL?.pathname;
  const diabled_pages: any = [
    // routes.ACCOUNT_SETTINGS,
    // routes.PAYMENT_SETTINGS,
    // routes.BILLING_SETTINGS,
    // routes.PREFERENCES_SETTINGS
  ];

  const currentYear = new Date().getFullYear();

  return !diabled_pages.some((element: any) => page.includes(element.to)) ? (
    <footer
      className={styles.footer_root}
      style={{ backgroundColor: AuthService.isLoggedIn() ? 'var(--color-dark)' : 'var(--color-main)' }}>
      <div className={styles.footer}>
        <div className={styles.footer_left}>
          <h3 className={styles.footer_contacts_header}>Pages</h3>
          <p>
            <a href='/'>Home</a> · <a href='/contact'>Contact</a> · <a href='/faq'>FAQ</a> · <a href='/login'>Login</a>{' '}
            · <a href='/register'>Register</a>
          </p>
          <p>
            <a href='/terms-conditions'>Terms and Conditions</a> · <a href='/cookies'>Cookies</a> ·{' '}
            <a href='/imprint'>Imprint</a> · <a href='/privacy-policy'>Privacy Policy</a>
          </p>
        </div>

        <div className={styles.footer_center}>
          <h3 className={styles.footer_contacts_header}>Contacts</h3>
          <FooterContact src={email} width='24' height='24' alt='Email' text='info@datatronic.cloud' />
          <FooterContact src={phone} width='24' height='24' alt='Telefon' text='+36 70 392 7242' />
          <FooterContact
            src={location}
            width='24'
            height='24'
            alt='Helyszín'
            text='2142 Nagytarcsa, Csonka János utca 6.'
          />
        </div>

        <div className={styles.footer_right}>
          <h3 className={styles.footer_social_header}>Social Media</h3>
          <p className={styles.footer_social_description}>Follow our social media pages so you dont miss any news!</p>
          <div className={styles.footer_icons}>
            {/* <FooterIcons
              href='https://www.facebook.com/datatronic.hu'
              ariaLabel='Datatronic Facebook'
              hoverColor={'#4267b1'}
              src={facebook}
              width='24'
              height='20'
              alt='Datatronic Facebook'
            />
            <FooterIcons
              href='https://www.youtube.com/channel/UCBfnmiGkgIRnNSkg-5KKlmQ'
              ariaLabel='Datatronic YouTube'
              hoverColor={'#ff0000'}
              src={youtube}
              width='24'
              height='20'
              alt='Datatronic YouTube'
            />
            <FooterIcons
              href='https://www.linkedin.com/company/datatronic-kubernetes-experts?originalSubdomain=hu'
              ariaLabel='Datatronic LinkedIn'
              hoverColor={'#0072b1'}
              src={linkedin}
              width='24'
              height='20'
              alt='Datatronic Instagram'
            /> */}
          </div>
        </div>

        <div className={styles.footer_bottom}>
          <img src={logo} alt='Az Datatronic logója' width='120' height='80' loading='lazy' />
          <p className={styles.company_name}>Copyright © {currentYear} | Datatronic Kft.</p>
        </div>
      </div>
    </footer>
  ) : (
    <></>
  );
};

export default Footer;
