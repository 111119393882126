import { useEffect, useState } from 'react';
import subscriptionQueries from '../../../api/queries/SubscriptionQueries';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useGeneralStore from '../../../store/GeneralStore';
import { useToasterLogic } from '../../../utils/useToasterLogic';
import { routes } from '../../../config/routes';

export const useSubscriptionLogic = () => {
  const [sortedServices, setSortedServices] = useState<any>();
  const [subscriptionNew, setSubscriptionNew] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { successToast, errorToast } = useToasterLogic();

  const { id } = useParams();
  const navigate = useNavigate();

  const { setSubscription, subscription }: any = useGeneralStore((state) => ({
    subscription: state.subscription,
    setSubscription: state.setSubscription
  }));

  const subscriptionFetchQuery = useQuery({
    queryKey: ['subscription', id],
    queryFn: () =>
      subscriptionQueries.fetchSubscriptionById({
        id: id
      }),
    onSuccess: (data: any) => {
      setSubscription(data);
    },

    retry: (failureCount, error: any) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const patchSubscription = useMutation({
    mutationFn: () =>
      subscriptionQueries.updateSubscription({ compId: subscription.compartmentId, body: subscriptionNew }),
    onSuccess: () => {
      queryClient.invalidateQueries(['user', id]);
      queryClient.invalidateQueries(['subscription', id]);
      successToast('Sikeres módosítás');
    },
    onError: () => {
      errorToast('Sikertelen módosítás');
    }
  });
  const deleteSubscription = useMutation({
    mutationFn: () =>
      subscriptionQueries.deleteSubscription({ compId: subscription.compartmentId, subId: subscription?.id }),
    onSuccess: () => {
      queryClient.invalidateQueries(['subscriptions']);
      navigate(routes.SUBSCRIPTIONS.to);
      successToast('Sikeres törlés');
    },
    onError: () => {
      errorToast('Sikertelen törlés');
    }
  });

  useEffect(() => {
    if ((subscription as any)?.productInstances) {
      let serviceObjects: any[] = [];

      (subscription as any)?.productInstances?.forEach((pI: any) => {
        if (pI?.groupName) {
          !serviceObjects?.find((sO: any) => sO?.groupName === pI?.groupName) &&
            serviceObjects.push({ groupName: pI?.groupName, instances: [] });
        }
      });
      (subscription as any)?.productInstances?.forEach((pI: any) => {
        if (serviceObjects[0]?.instances) {
          serviceObjects = serviceObjects?.map((sO: any) =>
            sO?.groupName === pI?.groupName && !sO?.instances?.find((i: any) => i.id === pI.id)
              ? { ...sO, instances: [...sO.instances, pI] }
              : { ...sO }
          );
        }
      });
      console.log(serviceObjects);

      setSortedServices(serviceObjects);
    }
    window.scrollTo(0, 0);
  }, [subscription]);

  return {
    subscription,
    subscriptionFetchQuery,
    sortedServices,
    patchSubscription,
    subscriptionNew,
    setSubscriptionNew,
    setOpen,
    open,
    deleteSubscription
  };
};
