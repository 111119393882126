import React from 'react';
import styles from './configurator.module.css';
import { modifierDTO } from '../../../types/ProductDto';
import ConfiguratorSlider from '../configuratorSlider/ConfiguratorSlider';
import ConfiguratorSelect from '../configuratorSelect/ConfiguratorSelect';
import ConfiguratorRadioButton from '../configuratorRadioButton/ConfiguratorRadioButton';
import ConfiguratorCheckbox from '../configuratorCheckbox/ConfiguratorCheckbox';
import { useConfiguratorLogic } from './useConfiguratorLogic';
import { CircularProgress } from '@mui/material';
import { MdClose, MdSave } from 'react-icons/md';
import ConfiguratorTextField from '../configuratorTextField/ConfiguratorTextField';
import ConfirmDialog from '../../general/confirmDialog/ConfirmDialog';

interface ConfiguratorProps {
  product: any;
  create?: boolean;
  instance?: any;
  closeAction?: any;
}

const Configurator: React.FC<ConfiguratorProps> = ({ product, instance, closeAction, create }) => {
  {
    /*eslint-disable */
  }
  const {
    modifiedItem,
    sliderModifiers,
    notSliderModifiers,
    dtoModifiers,
    mementoDtoModifiers,
    setDtoModifiers,
    patchInstance,
    createInstance,
    confirmOpen,
    setConfirmOpen
  } = useConfiguratorLogic(product, instance, create, closeAction);

  return (
    <div className={styles.configurator}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          opacity: 1,
          marginTop: '1.5rem'
        }}>
        {sliderModifiers?.length > 0 && modifiedItem && (
          <>
            <h3 className={styles.title}>Konfigurálás</h3>
            {sliderModifiers
              ?.sort((a: modifierDTO, b: modifierDTO) =>
                a.modifierKey.toLowerCase().localeCompare(b.modifierKey.toLowerCase())
              )
              ?.map((m: modifierDTO) => (
                <ConfiguratorSlider
                  key={`slider_${m?.id}`}
                  modifier={m}
                  dtoModifiers={dtoModifiers}
                  mementoDtoModifiers={mementoDtoModifiers}
                  setDtoModifiers={setDtoModifiers}
                  productId={modifiedItem?.id}
                />
              ))}
          </>
        )}
        {notSliderModifiers?.length > 0 && (
          <>
            {/*eslint-disable */}
            {notSliderModifiers
              ?.filter((m: modifierDTO) => m.modifierCategory === 'TEXT_FIELD')
              ?.sort((a: modifierDTO, b: modifierDTO) =>
                a.modifierKey.toLowerCase().localeCompare(b.modifierKey.toLowerCase())
              )
              ?.map((m: modifierDTO) => (
                <ConfiguratorTextField
                  modifier={m}
                  dtoModifiers={dtoModifiers}
                  mementoDtoModifiers={mementoDtoModifiers}
                  setDtoModifiers={setDtoModifiers}
                />
              ))}
          </>
        )}
        {notSliderModifiers?.length > 0 && (
          <>
            {/*eslint-disable */}
            {notSliderModifiers
              ?.filter((m: modifierDTO) => m.modifierCategory !== 'TEXT_FIELD')
              ?.sort((a: modifierDTO, b: modifierDTO) =>
                a.modifierKey.toLowerCase().localeCompare(b.modifierKey.toLowerCase())
              )
              ?.map((m: modifierDTO) =>
                m.modifierCategory === 'SELECT' ? (
                  <ConfiguratorSelect
                    modifier={m}
                    dtoModifiers={dtoModifiers}
                    mementoDtoModifiers={mementoDtoModifiers}
                    setDtoModifiers={setDtoModifiers}
                  />
                ) : m?.modifierCategory === 'RADIO_BUTTON' ? (
                  <ConfiguratorRadioButton
                    modifier={m}
                    dtoModifiers={dtoModifiers}
                    mementoDtoModifiers={mementoDtoModifiers}
                    setDtoModifiers={setDtoModifiers}
                  />
                ) : m?.modifierCategory === 'CHECKBOX' ? (
                  <ConfiguratorCheckbox
                    modifier={m}
                    dtoModifiers={dtoModifiers}
                    mementoDtoModifiers={mementoDtoModifiers}
                    setDtoModifiers={setDtoModifiers}
                  />
                ) : (
                  <p>{m?.description}</p>
                )
              )}
          </>
        )}
      </div>

      <div className={styles.button}>
        <button
          disabled={patchInstance.isLoading}
          className={styles.closeButton}
          type='button'
          onClick={create ? () => setConfirmOpen(true) : () => patchInstance.mutate()}>
          {patchInstance.isLoading ? (
            <>
              <CircularProgress size={24} />
              &ensp;{create ? 'Létrehozás' : 'Mentés'}
            </>
          ) : (
            <>
              <MdSave fontSize={20} />
              &ensp;{create ? 'Létrehozás' : 'Mentés'}
            </>
          )}
        </button>
        {closeAction && (
          <button className={styles.closeButton} type='button' onClick={() => closeAction()}>
            <MdClose fontSize={20} />
            &ensp;Mégse
          </button>
        )}
      </div>
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        action={() => createInstance.mutate()}
        title='Biztosan hozzá akarod adni a szolgáltatást?'
        loading={createInstance.isLoading}
      />
    </div>
  );
};

export default Configurator;
