import React from 'react';
import { useSubscriptionLogic } from './useSubscriptionLogic';
import styles from './subscription.module.css';
import Breadcrumb from '../../../components/groupComponents/Breadcrumb/Breadcrumb';
import ServiceList from '../../../components/userComponents/ServiceList/ServiceList';
import DataBlock from '../../../components/general/dataBlock/DataBlock';
import Switch from '@mui/material/Switch';
import { FaCheckCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { DiscountDTO } from '../../../types/DiscountDto';
import ProductInstanceCreator from '../../../components/userComponents/ProductInstanceCreator/ProductInstanceCreator';
import DiscountProvider from '../../../components/userComponents/DiscountProvider/DiscountProvider';
import { Button } from '@mui/material';
import ConfirmDialog from '../../../components/general/confirmDialog/ConfirmDialog';
import BillingForm from '../../../components/userComponents/BillingForm/BillingForm';

const Subscription = () => {
  const { sortedServices, patchSubscription, subscription, setSubscriptionNew, setOpen, open, deleteSubscription } =
    useSubscriptionLogic();

  const [editData, setEditData] = React.useState({});

  return (
    <div className={styles.mainContainer}>
      <Breadcrumb
        name={subscription?.id}
        to={-1}
        linkName={'Vissza a feliratkozásokhoz'}
        baseColor={'var(--color-main)'}
      />
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>{'Feliratkozás kezelése'}</h1>
      </div>
      <div className={styles.userDataWrapper}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Button
            style={{ background: 'red', marginBottom: '1.5rem' }}
            variant='contained'
            onClick={() => setOpen(true)}>
            Feliratkozás törlése
          </Button>
        </div>

        <div className={styles.userData}>
          <DataBlock title='Feliratkozás adatai'>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Azonosító</p>
              <p className={styles.data}>{subscription?.id}</p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Felhasználó</p>
              <p className={styles.data}>{subscription?.userId}</p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Compartment</p>
              <p className={styles.data}>{subscription?.compartmentId}</p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Létrehozás dátuma</p>
              <p className={styles.data}>{subscription?.createdDate}</p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Kredit egyenleg</p>
              <p className={styles.data}>{subscription?.creditBalance}</p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Kártyaregisztráció</p>
              <p className={styles.data}>
                {subscription?.isCardRegistered ? (
                  <>
                    Regisztrálva <FaCheckCircle color='green' />{' '}
                  </>
                ) : (
                  <>
                    Nincs regisztrálva <MdClose color='red' />{' '}
                  </>
                )}
              </p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Valuta</p>
              <p className={styles.data}>{subscription?.currency}</p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Státusz</p>
              <p className={styles.data}>
                <Switch
                  style={{ color: subscription?.status == 'ACTIVE' ? 'green' : 'red' }}
                  checked={subscription?.status == 'ACTIVE' ?? false}
                  onChange={() => {
                    setSubscriptionNew({ ...subscription, status: subscription?.active ? 'INACTIVE' : 'ACTIVE' });
                    patchSubscription.mutate();
                  }}></Switch>
              </p>
            </div>
            <div className={styles.dataRow}>
              <p className={styles.dataTitle}>Szolgáltatások száma</p>
              <p className={styles.data}>{subscription?.productInstances?.length}</p>
            </div>
          </DataBlock>
        </div>
        <div className={styles.userData}>
          <DataBlock title='Számlázási adatok'>
            <BillingForm />
          </DataBlock>
        </div>
        <div className={styles.userData}>
          <DataBlock title='Feliratkozás szolgáltatásai'>
            <ProductInstanceCreator />
            <div className={styles.services}>
              <ServiceList sortedServices={sortedServices} />
            </div>
          </DataBlock>
        </div>
        <div className={styles.userData}>
          <DataBlock title='Aktív leárazások'>
            <DiscountProvider data={editData} setData={setEditData} />
            <div className={styles.activeDiscounts}>
              {(subscription as any)?.discounts?.length > 0 ? (
                (subscription as any)?.discounts?.map((d: DiscountDTO) => (
                  <div key={`discount_${d?.id}`} className={styles.discount}>
                    <h4>ID: {d?.id}</h4>
                    <p className={styles.discountInfo}>
                      Érvényesség: {new Date(d?.endDate).toLocaleString().split(' ').join('')}
                    </p>
                    <p className={styles.discountInfo}>
                      Kedvezmény: {d?.discountRate > 0 ? d?.discountRate * 100 : d?.discountRate}{' '}
                      {d?.discountRate > 0 ? '%' : 'EUR'}
                    </p>
                    <p className={styles.discountInfo}>
                      Címkék:{' '}
                      {d?.typeMatcher?.map((el) => {
                        return el + ' ';
                      })}
                    </p>
                  </div>
                ))
              ) : (
                <h3>Nincs aktív leárazás</h3>
              )}
            </div>
          </DataBlock>
        </div>
        <ConfirmDialog
          title='Biztosan törölni akarod ezt a feliratkozást?'
          loading={deleteSubscription.isLoading}
          open={open}
          setOpen={setOpen}
          action={() => deleteSubscription.mutate()}
        />
      </div>
    </div>
  );
};

export default Subscription;
