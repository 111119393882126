import {
  TextField,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  Slider,
  CircularProgress,
  DialogContent,
  Checkbox,
  IconButton
} from '@mui/material';
import { Formik } from 'formik';
import * as React from 'react';
import styles from './discountEditor.module.css';
import { useValidationLogic } from '../../../validation/useValidationLogic';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { MdClose } from 'react-icons/md';

interface DiscountEditorProps {
  open: any;
  setClose: any;
  data: any;
  putDiscount: any;
  tags: any;
}

const DiscountEditor: React.FC<DiscountEditorProps> = ({ open, setClose, data, putDiscount, tags }) => {
  const { DiscountSchema } = useValidationLogic();
  const buttonRef = React.useRef(null);
  const [formData, setFormData] = React.useState({
    code: data?.code ?? '',
    //type: data?.type,
    //discountAmount: data?.discountAmount,
    typeMatcher: data.typeMatcher ?? [],
    discountRate: data?.discountRate ?? 0,
    createdDate: dayjs(data?.createdDate),
    isPublic: data?.isPublic ?? false,
    endDate: dayjs(data?.endDate)
  });

  React.useEffect(() => {
    setFormData({
      code: data?.code ?? '',
      //type: data?.type,
      //discountAmount: data?.discountAmount,
      typeMatcher: data.typeMatcher ?? [],
      discountRate: data?.discountRate ?? 0,
      createdDate: dayjs(data?.createdDate),
      isPublic: data?.isPublic ?? false,
      endDate: dayjs(data?.endDate)
    });
  }, [data]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        className={styles.dialog}
        open={open}
        onClose={() => {
          setClose(false);
        }}>
        <DialogTitle style={{ textAlign: 'center', position: 'relative' }}>
          Kupon módosítása
          <IconButton
            style={{ position: 'absolute', top: '0', right: '0' }}
            onClick={() => {
              setClose(false);
            }}>
            <MdClose color='red' />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={formData}
            validationSchema={DiscountSchema}
            onSubmit={() => {
              putDiscount.mutate({ id: data.id, data: formData });
              setClose(false);
            }}>
            {({ values, errors, touched, handleSubmit }) => (
              <form onSubmit={handleSubmit} className={styles.mainForm}>
                {/*formData?.type === 'Összeges' ? (
                  <div className={styles.inputContainer}>
                    <div className={styles.inputLabel}>Kedvezmény mértéke:</div>
                    <div className={styles.inputField}>
                      <Slider
                        min={0}
                        max={500}
                        autoFocus
                        valueLabelDisplay='on'
                        value={values.discountAmount ?? 0}
                        onChange={(e, val) => {
                          setFormData({ ...formData, discountAmount: val });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.inputContainer}>
                    <div className={styles.inputLabel}>Kedvezmény százaléka:</div>
                    <div className={styles.inputField}>
                      <Slider
                        min={0}
                        max={100}
                        step={1}
                        autoFocus
                        valueLabelDisplay='on'
                        value={values.discountRate ?? 0}
                        onChange={(e, val) => {
                          setFormData({ ...formData, discountRate: val });
                        }}
                      />
                    </div>
                  </div>
                      )*/}
                <div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Kedvezmény százaléka:</div>
                  <div className={styles.inputField}>
                    <Slider
                      min={0}
                      max={100}
                      step={1}
                      autoFocus
                      valueLabelDisplay='on'
                      value={values.discountRate * 100 ?? 0}
                      onChange={(e, val) => {
                        setFormData({ ...formData, discountRate: (val as any) / 100 });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Kupon kód:</div>
                  <div className={styles.inputField}>
                    <TextField
                      variant='standard'
                      value={values.code}
                      fullWidth
                      autoFocus
                      className={styles.input}
                      onChange={(e) => {
                        setFormData({ ...formData, code: e.target.value });
                      }}
                      error={touched.code && Boolean(errors.code)}
                      helperText={touched.code && Boolean(errors.code)}
                    />
                  </div>
                </div>
                {/*<div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Kupon típus:</div>
                  <div className={styles.inputField}>
                    <Select
                      variant='standard'
                      fullWidth
                      autoFocus
                      onChange={(e: any) => {
                        setFormData({ ...formData, type: e.target.value, discountRate: 0, discountAmount: 0 });
                      }}
                      value={values.type ?? ''}>
                      <MenuItem key={0} value={'Összeges'}>
                        Összeges
                      </MenuItem>
                      <MenuItem key={1} value={'Százalékos'}>
                        Százalékos
                      </MenuItem>
                    </Select>
                  </div>
                    </div>*/}
                <div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Hozzá tartozó címkék:</div>
                  <div className={styles.inputField}>
                    <Select
                      variant='standard'
                      fullWidth
                      multiple
                      autoFocus
                      onChange={(e: any) => {
                        setFormData({ ...formData, typeMatcher: e.target.value });
                      }}
                      value={values.typeMatcher ?? ''}>
                      {tags?.map((el: any) => {
                        return (
                          <MenuItem key={el.id} value={el.name}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Kedvezmény kezdete:</div>
                  <div className={styles.inputField}>
                    <DateTimePicker
                      autoFocus
                      value={values.createdDate}
                      onChange={(e: any) => {
                        setFormData({ ...formData, createdDate: e });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Kedvezmény vége:</div>
                  <div className={styles.inputField}>
                    <DateTimePicker
                      autoFocus
                      value={values.endDate}
                      onChange={(e: any) => {
                        setFormData({ ...formData, endDate: e });
                      }}
                    />
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <div className={styles.inputLabel}>Publikus:</div>
                  <div className={styles.inputField}>
                    <Checkbox
                      checked={values.isPublic}
                      onChange={(e: any) => {
                        setFormData({ ...formData, isPublic: e.target.checked });
                      }}
                    />
                  </div>
                </div>
                <button ref={buttonRef} disabled={false} type='submit' style={{ display: 'none' }}>
                  SUBMIT
                </button>
              </form>
            )}
          </Formik>
          <Button
            variant='contained'
            onClick={() => (buttonRef.current as any).click()}
            className={styles.button}
            disabled={putDiscount.isLoading}>
            {putDiscount.isLoading ? <CircularProgress size={24} /> : 'Kedvezmény módosítása'}
          </Button>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default DiscountEditor;
